/*
* This context handles injecting the route tree and provides hooks for interacting
* with the active route path object.
*
* The hook for this context `useRouter` provides the following utilities:
*   activeRouteTree: The routeTree object filtered for the active user
*   activeRoutes: An array of the matched routeTree route segments in order from root to leaf
*     { route, match } objects
*   activeRoute: The active 'leaf' of the routeTree
*     { route, match } object
*   activeRouteAddress: The address of the activeRoute interpolated with params
*     e.g. tasks.moving-plan.rates
*   params: All of the params from the activeRoute (equivalent to activeRoute?.match?.params)
*/

import React, { createContext, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser, format } from '@moved/services';

import { RenderRoutes } from '../components/RenderRoutes';
import { formatRouteTree, findMatchingRoutes } from '../services/router';

const RouterContext = createContext();
const useRouter = () => useContext(RouterContext);

const RouterProvider = ({
  routeTree, // the full application route tree
  defaultDomains=[], // the default domains for this application
  basePath='', // the base path for this router (usually the root path)
  children,
}) => {

  const user = useUser();
  const location = useLocation();

  const activeRouteTree = useMemo(
    () => formatRouteTree(routeTree, defaultDomains, user),
    [routeTree, defaultDomains.join(','), user]
  );

  const activeRoutes = useMemo(
    () => findMatchingRoutes(activeRouteTree, basePath, location.pathname),
    [activeRouteTree, basePath, location.pathname]
  );

  const activeRoute = activeRoutes?.at(-1);
  const params = activeRoute?.match?.params ?? {};

  const activeRouteAddress = format.interpolate(
    activeRoutes.map(({ route }) => route.address).filter(Boolean).join('.'),
    params,
  );

  // map the context API
  const context = {
    activeRouteTree,
    activeRoutes,
    activeRoute,
    activeRouteAddress,
    params,
    basePath, // included for the RouteTree component
  };

  // debug: log all route addresses
  // const fetchAllRouteAddresses = (routes, parent) => {
  //   let result = [];
  //   for (const route of routes) {
  //     const address = [parent, route.address].filter(Boolean).join('.');
  //     if(route?.children?.length) result.push(...fetchAllRouteAddresses(route.children, address));
  //     else if(route.address) result.push(address);
  //   }
  //   return [...new Set(result)];
  // };
  // console.log(fetchAllRouteAddresses(routeTree).join('\n'));

  return (
    <RouterContext.Provider value={context}>
      { children }
    </RouterContext.Provider>
  );
};

const RouteTree = () => {
  const { activeRouteTree, basePath } = useRouter();
  return (
    <RenderRoutes routes={activeRouteTree} basePath={basePath} />
  );
};

export {
  RouterProvider,
  RouteTree,
  useRouter,
};
