import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { merge } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { Button, Notebox, ClipboardText, ClipboardIcon } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import { Screen } from '../../shared';

import CSS from './styles/Instructions.module.scss';

export const Instructions = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const { actions, helpers, selectors } = taskDefinition;

  const pending = selectors.useSubmitPetscreeningVerificationTaskPending();
  const moveStep = selectors.useActiveMoveStep();
  const building = selectors.useBuilding(moveStep?.building?.slug);

  const { content, title, subtitle, cta_text, side_column_image_url } = taskDetails?.instruction_content || {};
  const profile = helpers.getActiveRequest(taskDetails);

  const submit = () => {
    // if already submitted, just navigate forward
    if(taskDetails.submitted_at) return nextScreen();
    // otherwise submit and navigate forward
    dispatch(actions.submit(id))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  const linkToPetscreening = () => {
    dispatch(actions.click(profile?.id))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  const startScreeningSnippet = useSnippet('tasks.petscreening.instructions.cta_text', 'PetScreening linkout button text');

  return (
    <Screen taskDefinition={taskDefinition} screen={merge({}, screen, { title, subtitle })}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            <Notebox
              title='Attention all residents: Your digital pet profile is required!'
              body='We require all applicants and residents to complete one of our three profile types: No Pet/Animal, Household Pet or Assistance Animal.'
              color='yellow'
              icon={{ library: 'code', symbol: 'Warning-2' }}
            />
            <Notebox
              title={`Don't forget to use the same email address!`}
              body={(
                <div className='stackVertical gap-12'>
                  <div className='labelM'>
                    When completing your PetScreening profile, please use the
                    same email address associated with your tenant application
                    to ensure your information is accurately linked.
                  </div>
                  <div className='stackHorizontal items-center'>
                    <ClipboardIcon text={moveStep?.tenant?.email} />
                    <ClipboardText text={moveStep?.tenant?.email} className='contentPrimary' />
                  </div>
                </div>
              )}
              color='yellow'
              icon={{ library: 'code', symbol: 'Warning-2' }}
            />
          </>
        )}
      >
        <div className={CSS.content_wrapper}>
          <div className={CSS.instructions}>
            <ResidentContentRenderer content={content}
              tags={{
                due_date: profile?.due_date ?
                  format.date(profile?.due_date,'date') :
                  'TBD',
              }}
            />
            { profile ? (
              <>
                <Button
                  color='primary'
                  size='large'
                  className={classNames('marginTop-20',CSS.petscreeningButton)}
                  onClick={linkToPetscreening}
                  icon={{ symbol: 'Up-right', library: 'navigation' }}
                  iconPosition='right'
                  text={startScreeningSnippet ?? 'Start screening'}
                />
              </>
            ) : (
              <Notebox
                title='PetScreening link not yet available'
                body='Your unique invitation link from PetScreening is not accessible yet. Please check back in 48 hours. If still not available, contact support.'
                color='yellow'
                icon={{ library: 'code', symbol: 'Warning-2' }}
                className='margin-28 marginBottom-0'
              />
            )}

            <div className={classNames('marginTop-20 labelM contentSecondary')}>
              This process is managed by <img className={classNames('marginLeft-6 marginRight-6', CSS.logo)} src={`${process.env.REACT_APP_S3_ASSET_URL}/service-providers/petscreening/petscreening-logo.png`} alt='PetScreening'/> on behalf of {building.name}
            </div>
          </div>

          { side_column_image_url && (
            <div className={CSS.side_column} style={{ backgroundImage: `url(${side_column_image_url})`}} />
          )}

        </div>
      </Screen.Content>

      <Screen.Actions>
        <Button
          text={cta_text || 'I have completed my PetScreening'}
          size='large'
          onClick={submit}
          disabled={!profile || pending}
          className='width-full'
        />
      </Screen.Actions>

    </Screen>
  );
}
