import { matchPath, generatePath } from 'react-router-dom';

const findMatchingRoutes = (routes=[], base='', currentLocation) => {
  let match;
  const route = routes.find(route => (match = matchPath(currentLocation, {
    path: `${base}${route.path}`.replace(/\/+/g, '/'),
    exact: route.exact,
  })));
  if(match) return [
    { route, match },
    ...(findMatchingRoutes(route.children, match.path, currentLocation) ?? []),
  ];
};

const formatRouteTree = (routeTree=[], defaultDomains, user) => {
  return routeTree
    // handle route defaults and conveniences
    .map(route => {
      if(!route.restrict) route.restrict = [...defaultDomains];
      if(route.allowGuest === true && !route.restrict.includes('guest')) route.restrict.push('guest');
      return route;
    })
    // remove invalid routes
    .filter(route => route.component || route.redirect || (route.children && route.children.length > 0))
    // remove routes that can't be accessed by domain
    .filter(route => (route?.restrict ?? []).find(domain => user.hasDomain(domain)))
    // remove routes that can't be accessed by user abilities
    .filter(route => route.abilities == null || user.hasAbilities(route.abilities))
    // recurse to filter children routes
    .map(route => {
      if(route.children) route.children = formatRouteTree(route.children, defaultDomains, user);
      return route;
    });
};

export { findMatchingRoutes, formatRouteTree };
